// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/ban-types */
import React, { Fragment } from 'react';
import {
  Block,
  Flex,
  Spacer,
  Text,
  ThemePicker,
  ExtendCSS,
  useTheme,
} from 'vcc-ui';
import { animated, useTransition, useSprings } from 'react-spring';
import { ReferenceArrayField } from '@vcc-www/content-management-jss-client';
import { isStorybook } from '@vcc-www/storybook-utils/constants';
import { Click } from '@vcc-www/buttons';
import AnimatedReveal from '@vcc-www/animated-reveal';
import Disclaimer from '@vcc-www/disclaimer';
import ScrollableAccordionProps, {
  ScrollableAccordionItem,
} from './ScrollableAccordion.props';

const getTextSprings = (currentlyOpen: number) => (i: number) =>
  currentlyOpen === i
    ? {
        maxHeight: 500,
        opacity: 1,
        textOpacity: 1,
      }
    : {
        maxHeight: 0,
        opacity: 0.7,
        textOpacity: 0,
      };

const id = <T,>(a: T) => a;

export interface Props {
  enableReveal?: boolean;
}

type AccordionIndicatorProps = {
  items: ReferenceArrayField<ScrollableAccordionItem>;
  setCurrentlyOpen: Function;
  currentlyOpen: number;
};

const AccordionIndicator: React.FC<AccordionIndicatorProps> = ({
  items,
  setCurrentlyOpen,
  currentlyOpen,
}) => {
  return (
    <Block extend={indicatorWrapperCSS}>
      {items.map((item, i) => {
        // Analytics tracking for when the user clicks on an accordion item
        const eventAction = 'expand';
        const eventLabel = `${i + 1} | ${item.title}`;
        return (
          <Fragment key={i}>
            <Click
              trackEventLabel={eventLabel}
              // @ts-ignore Check if this is correct value to track for ga4
              trackEventAction={eventAction}
              onClick={() => setCurrentlyOpen(i)}
            >
              <Block extend={indicatorCSS(i, currentlyOpen)} />
            </Click>
          </Fragment>
        );
      })}
    </Block>
  );
};
const ScrollableAccordion: React.FC<ScrollableAccordionProps & Props> = ({
  content,
  enableReveal = true,
  showAccordionIndicator = false,
  fullWidth = false,
}) => {
  const { items, disclaimer } = content;
  const [currentlyOpen, setCurrentlyOpen] = React.useState(0);
  const image = items[currentlyOpen] ?? items[items.length - 1];
  const imageTransition = useTransition(image, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    key: image.mobileImage?.src || image.desktopImage?.src || image.title,
  });

  const [textSprings, set] = useSprings(
    items.length,
    getTextSprings(currentlyOpen),
  );

  React.useEffect(() => {
    // @ts-ignore
    set(getTextSprings(currentlyOpen));
  }, [currentlyOpen, set]);

  const { breakpoints } = useTheme();
  const srcsetBreakpoint = breakpoints.untilM.match(/\((.*?)\)/)?.[0];

  return (
    <Block extend={{ position: 'relative' }}>
      <Flex
        extend={({ theme: { baselineGrid } }) => ({
          pointerEvents: 'none',
          top: 0,
          paddingTop: baselineGrid * 12,
          paddingBottom: baselineGrid * 3,
          minHeight: '100vh',
          width: '100%',
          alignItems: 'flex-start',
          justifyContent: 'center',
          backgroundColor: 'primitive.black',
          fromL: {
            paddingLeft: baselineGrid * 20,
          },
        })}
      >
        {imageTransition((styles, item) => (
          <Block
            key={
              image.mobileImage?.src || image.desktopImage?.src || image.title
            }
            style={styles as any}
            as={animated.picture}
            extend={{
              height: '100%',
              width: '100%',
              maxWidth: '100%',
              objectFit: 'cover',
              objectPosition: 'top',
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
            }}
          >
            <source media={srcsetBreakpoint} srcSet={item.mobileImage?.src} />
            <Block
              as="img"
              extend={{
                maxWidth: '100%',
                maxHeight: '100%',
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
              src={item.desktopImage?.src}
              alt={item.desktopImage?.alt}
            />
          </Block>
        ))}
        <AnimatedReveal enable={!isStorybook && enableReveal}>
          <ThemePicker variant="dark">
            <>
              {/* Accordion Indicator */}
              {showAccordionIndicator && (
                <AccordionIndicator
                  items={items}
                  setCurrentlyOpen={setCurrentlyOpen}
                  currentlyOpen={currentlyOpen}
                />
              )}

              {textSprings.map(({ maxHeight, opacity, textOpacity }, i) => {
                const item = items[i];
                // Analytics tracking for when the user clicks on an accordion item
                const eventAction = 'expand';
                const eventLabel = `${i + 1} | ${item.title}`;
                return (
                  <Fragment key={i}>
                    {!!i && <Spacer size={4} />}
                    <Block
                      extend={itemWrapperCSS(fullWidth)}
                      data-autoid="scrollableAccordion:items"
                    >
                      <Click
                        extend={({ theme: { baselineGrid } }) => ({
                          display: 'flex',
                          paddingLeft: `${baselineGrid * 3}px !important`,
                          pointerEvents: 'auto',
                        })}
                        trackEventLabel={eventLabel}
                        // @ts-ignore Check if this is correct value to track for ga4
                        trackEventAction={eventAction}
                        onClick={() => setCurrentlyOpen(i)}
                      >
                        <h2
                          className="heading-2"
                          style={{ opacity: opacity.to(id) as any }}
                          data-autoid="scrollableAccordion:toggle"
                        >
                          {i === currentlyOpen ? '-' : '+'}
                        </h2>
                        <Text
                          as={animated.h3 as any}
                          style={{ opacity: opacity.to(id) as any }}
                          extend={({ theme: { baselineSubGrid } }) => ({
                            paddingLeft: baselineSubGrid,
                          })}
                          variant="ootah"
                          subStyle="emphasis"
                          data-autoid="scrollableAccordion:title"
                        >
                          {item.title}
                        </Text>
                      </Click>
                      <Text
                        as={animated.p as any}
                        style={
                          {
                            maxHeight: maxHeight,
                            opacity: textOpacity,
                          } as any
                        }
                        extend={({ theme: { baselineGrid } }) => ({
                          paddingTop: baselineGrid * 2,
                          paddingLeft: baselineGrid * 6,
                          maxWidth: fullWidth ? '100%' : 450,
                        })}
                        variant="columbus"
                        data-autoid="scrollableAccordion:description"
                      >
                        {item.text}
                      </Text>
                    </Block>
                  </Fragment>
                );
              })}
              {disclaimer && (
                <Disclaimer extend={disclaimerCSS} text={disclaimer} />
              )}
            </>
          </ThemePicker>
        </AnimatedReveal>
      </Flex>
    </Block>
  );
};

const disclaimerCSS: ExtendCSS = ({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  paddingTop: theme.baselineGrid * 2,
  paddingBottom: theme.baselineGrid * 2,
  zIndex: 1,
});

const itemWrapperCSS =
  (fullWidth: ScrollableAccordionProps['fullWidth']): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    paddingRight: baselineGrid * 6,
    maxWidth: fullWidth ? '100%' : 650,
    zIndex: 1,
    position: 'relative',
    ...(fullWidth && {
      fromL: {
        paddingRight: baselineGrid * 23,
      },
    }),
  });

const indicatorWrapperCSS: ExtendCSS = ({ theme: { baselineSubGrid } }) => ({
  position: 'absolute',
  left: 20 * baselineSubGrid,
  display: 'none',
  fromL: {
    display: 'block',
  },
});

const indicatorCSS =
  (i: number, currentlyOpen: number): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    width: '4px',
    height: 11 * baselineGrid,
    background: '#ffffff',
    opacity: i === currentlyOpen ? '1' : '0.3',
    marginBottom: 2 * baselineGrid,
  });

export default ScrollableAccordion;
